import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");

// Customizable Area Start
import jsPDF, { HTMLOptions } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";

export interface ValidResponseType {
  data: object;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

export interface ErrorPayloadType {
  key: string;
}

export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
}

export interface NavigationMenuSuccess {
  data: {
  user_survey_navigation_menu: {
    id: number;
    survey_name: string;
    user_survey_sections: Array<NavigationSection>;
    }
  }
}

export interface NavigationSection {
  id: number;
  survey_section_name: string;
  user_survey_sub_sections: Array<NavigationSubSection>;
}

export interface NavigationSubSection {
  id: number;
  survey_sub_section_name: string;
}

export interface NavigationMenuError {
  errors: string;
}

export interface SurveyHistoryInfo {
  data: {
      id: number;
      type: string;
      attributes: {
          id: number;
          survey_name: string;
          date_created: string;
          last_edited: string;
          created_by: string;
          owner: string;
          attached_photo_files: number;
          attached_video_files: number;
          total_uploaded_files: number;
      }
  }
}

export interface SectionDetails {
  data: {
      id: string;
      type: string;
      attributes: {
          id: number;
          user_survey_id: number;
          user_survey_section_name: string;
            is_subsection_present: boolean;
            user_survey_sub_sections: Array<SurveySubSection>;
            survey_template_details: {
                checkbox_1: boolean,
                checkbox_2: boolean
            }
        }
    }
}

export interface SurveySubSection {
  id: number;
  user_survey_sub_section_name: string;
  user_survey_sub_section_details: Array<SurveySubSectionDetails>;
}

export interface SurveySubSectionDetails {
  id: number;
  field_name: string;
  field_value: string;
  field_value_multiple: string[];
  field_type: string;
  field_options: string[];
  photos: Array<MediaFiles>;
  videos: Array<MediaFiles>;
}
export interface MediaFiles {
    id: number;
    filename: string;
    content_type: string;
    description: null;
    created_at: string;
    byte_size: number;
    url: string;
}

interface AccordionOpenState {
  [key: string]: boolean;
}

interface RefObject {
  [key: string]: React.RefObject<HTMLHeadingElement>;
}

interface ActiveLink {
  data: {
      id: string;
      link_share_id: number;
      active_link: string;
  }
}

interface ActiveError {
  errors: string;
}

interface WinZipResponse {
  data: {
      uploaded_files_winzip: string;
  }
}

export interface SaveAsPdfDetails {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      survey_name: string;
      date_created: string;
      last_edited: string;
      created_by: string;
      owner: string;
      progress: string;
      user_survey_sections: Array<SurveySections>;
    }
  }
}

export interface SurveySections {
  id: number;
  user_survey_section_name: string;
  is_survey_subsection_present: boolean;
  user_survey_sub_sections: Array<SurveySubSection>;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  collapsed: boolean;
  openSections: Set<number>;
  navigationMenuSuccess: NavigationMenuSuccess;
  openDrawer: boolean;
  surveyInfo: SurveyHistoryInfo;
  sectionDetails: SectionDetails;
  sectionId: number;
  isSelected: boolean;
  sectionSelectedId: number;
  sectionIdOneTime: number;
  accordionOpenState: AccordionOpenState;
  openModal: boolean;
  hasPreviousSection: boolean;
  hasNextSection: boolean;
  winZipLink: string;
  zipLoader: boolean;
  saveAsPdfDetails: SaveAsPdfDetails;
  openPopup: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}
export default class GuestDashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getDashboardNavigationMenuApiCallId: string = "";
  getSurveyInfoApiCallId: string = "";
  getSectionDetailsApiCallId: string = "";
  headingRefs: RefObject = {};
  videoRefs: React.RefObject<HTMLVideoElement>[];
  getActiveLinkApiCallId: string = "";
  getWinZipApiCallId: string = "";
  getSaveAsPdfApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.videoRefs=[];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      collapsed: false,
      openSections: new Set<number>(),
      navigationMenuSuccess: {
        data: {
        user_survey_navigation_menu: {
          id: 0,
          survey_name: "",
          user_survey_sections: [{
            id: 0,
            survey_section_name: "",
            user_survey_sub_sections: [{
              id: 0,
              survey_sub_section_name: ""
            }]
          }]
        }
      }
      },
      openDrawer: false,
      surveyInfo: {
        data: {
          id: 0,
          type: "",
          attributes: {
            id: 0,
            survey_name: "",
            date_created: "",
            last_edited: "",
            created_by: "",
            owner: "",
            attached_photo_files: 0,
            attached_video_files: 0,
            total_uploaded_files: 0
          }
        }
      },
      sectionDetails: {
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            user_survey_id: 0,
            user_survey_section_name: "",
            is_subsection_present: false,
            user_survey_sub_sections: [],
            survey_template_details: {
              checkbox_1: false,
              checkbox_2: false
          }
          }
        }
      },
      sectionId: 0,
      isSelected: false,
      sectionSelectedId: 0,
      sectionIdOneTime: 0,
      accordionOpenState: {},
      openModal: false,
      hasPreviousSection: false,
      hasNextSection: false,
      winZipLink: "",
      zipLoader: false,
      saveAsPdfDetails: {
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            survey_name: "",
            date_created: "",
            last_edited: "",
            created_by: "",
            owner: "",
            progress: "",
            user_survey_sections: []
          }
        }
      },
      openPopup: false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getActiveLink();
    // Customizable Area End
  }

  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }else if (this.isInValidResponse(responseJson)) {
        this.apiFailCallBack(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

    // Customizable Area Start
  guestDashboardApiCall = (data: APIPayloadType) => {
    let { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiFailCallBack = (apiRequestCallID: string, responseJSON: ActiveError) => {
    if (apiRequestCallID === this.getActiveLinkApiCallId) {
      this.setState({
        openModal: true
      });
   } 

    if (apiRequestCallID === this.getWinZipApiCallId) {
      this.setState({
        winZipLink: responseJSON.errors,
        zipLoader: false
      }, () => {
        this.fileErrorPopup();
      });
   } 
 };

  apiSuccessCallBacks = (apiRequestCallID: string, responseJson: NavigationMenuSuccess & SurveyHistoryInfo & SectionDetails & ActiveLink & WinZipResponse & SaveAsPdfDetails) => {
    if (apiRequestCallID === this.getDashboardNavigationMenuApiCallId) {
      this.setState({
        navigationMenuSuccess: responseJson,
        sectionId: responseJson.data.user_survey_navigation_menu.user_survey_sections[0].id,
        sectionIdOneTime: responseJson.data.user_survey_navigation_menu.user_survey_sections[0].id
      },()=>{
        this.getSectionsUserDetails();
        
      })
    }

    if (apiRequestCallID === this.getSurveyInfoApiCallId) {
      this.setState({
        surveyInfo: responseJson
      });
   }   
   
    if (apiRequestCallID === this.getSectionDetailsApiCallId) {
      const {checkbox_2} = responseJson.data.attributes.survey_template_details
      const data=responseJson.data.attributes.user_survey_sub_sections
      let sectionData=responseJson
      if(checkbox_2){
          const finalData=data.map(column=>{
            const nonEmtpyFields=column.user_survey_sub_section_details.filter((section)=>{
              return section.field_value!=="" || section.field_value!==null
            })
            column.user_survey_sub_section_details=nonEmtpyFields
            return column
          })
          sectionData.data.attributes.user_survey_sub_sections=finalData
      }
      this.setState({
        sectionDetails: sectionData,
        sectionSelectedId: responseJson.data.id,
      });
   } 

   if (apiRequestCallID === this.getActiveLinkApiCallId) {
       this.handleCheckLinkShare(responseJson.data.active_link);
   } 

   if (apiRequestCallID === this.getWinZipApiCallId) {
    this.setState({
      winZipLink: responseJson.data.uploaded_files_winzip,
      zipLoader: false
    },()=>{
      this.zipFile();
    });
   } 
   
   if (apiRequestCallID === this.getSaveAsPdfApiCallId) {
    this.setState({
      saveAsPdfDetails: responseJson
    });
   } 
  };

  getSidebarNavigationData = () => {
    const linkShareId = window.location;
    let arrayPath = linkShareId.pathname.split("/");
    let shareId = arrayPath[arrayPath.length-1]
    this.getDashboardNavigationMenuApiCallId = this.guestDashboardApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.sidebarNavigationAPiEndPoint}?guest_survey_id=${shareId}`,
    });
  };

  toggleCollapsed = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  toggleSection = (sectionKey: number) => {
    this.setState({
        sectionId: sectionKey,
        hasPreviousSection: false,
        hasNextSection: false
    },()=>{
      this.getSectionsUserDetails();
      this.handlePagination();
    });

    this.setState((prevState) => {
      const openSections = new Set<number>(prevState.openSections);
      if (openSections.has(sectionKey)) {
        openSections.delete(sectionKey);
      } else {
        openSections.add(sectionKey);
      }
      return { openSections };
    });
  };

  handleDrawer=()=>{
    this.setState({
      openDrawer:!this.state.openDrawer
    });
  };

  getSurveyInfoDetails = () => {
    const linkShareNewId = window.location;
    let pathLink = linkShareNewId.pathname.split("/");
    let shareLinkId = pathLink[pathLink.length-1]
    this.getSurveyInfoApiCallId = this.guestDashboardApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.historyDetailsAPiEndPoint}?guest_survey_id=${shareLinkId}`,
    });
  };

  getSectionsUserDetails = () => {
    const linkShareNewId = window.location;
    let pathLink = linkShareNewId.pathname.split("/");
    let shareLinkId = pathLink[pathLink.length-1];
    this.getSectionDetailsApiCallId = this.guestDashboardApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.sectionApiEndPoint}?guest_survey_id=${shareLinkId}&guest_survey_section_id=${this.state.sectionId}`
    });
  };

  handleAccordion = (accordionId:  number) => {
    this.setState({
      sectionId: accordionId
    },()=>{
      this.getSectionsUserDetails();
       
    })
    this.setState((prevState) => ({
        accordionOpenState: {
            ...prevState.accordionOpenState,
            [accordionId]: !prevState.accordionOpenState[accordionId],
        },
    }));
  };

  handleSubsectionAccordions = (accordionId:  number, subsectionName: string) => {
    this.setState({
      sectionId: accordionId
    },()=>{
      this.getSectionsUserDetails()
      this.handleScrollToSubSection(subsectionName)
      this.handleAccordion(accordionId); 
    });
  };

  handleScrollToSubSection = (subsectionName: string) => {
    const headingRef = this.headingRefs[subsectionName];
    if (headingRef && headingRef.current) {
        const yOffset = -100;
        const topY = headingRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: topY, behavior: 'smooth' });
    }
  };

  handleViewSize = (viewUrl: string) => {
    window.open(viewUrl);
  };

  handleFullScreen(videoRef: React.RefObject<HTMLVideoElement>) {
    if (videoRef && videoRef.current) {
        const elementVideo = videoRef.current;
        if (elementVideo.requestFullscreen) {
          elementVideo.requestFullscreen();
        }
      }
  };

  getActiveLink = () => {
    const linkShareNewId = window.location;
    let pathLink = linkShareNewId.pathname.split("/");
    let activeLinkId = pathLink[pathLink.length-1];
    this.getActiveLinkApiCallId = this.guestDashboardApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.activeLinkEndPoint}${activeLinkId}`
    });
  };

  handleCheckLinkShare = (activeLink: string) =>{
    let newPath =  window.location.href;
    if(activeLink === newPath){
      this.setState({
        openModal: false
      },() => {
        this.getSidebarNavigationData();
        this.getSurveyInfoDetails();
        this.getSectionsUserDetails();
        this.handlePagination();
        this.getSavePdfDetails();
      });
    }else{
      this.setState({
        openModal: true
      });
      }
  };

  nextPagination = () => {
    const { navigationMenuSuccess, sectionId } = this.state;
    const sectionIndex = navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections.findIndex(
      (section) => section.id === sectionId
    );
    if (sectionIndex < navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections.length - 1) {
      const nextSectionId = navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections[sectionIndex + 1].id;
      this.setState(
        { sectionId: nextSectionId, hasNextSection: false, hasPreviousSection: false },
        () => {
          this.getSectionsUserDetails();
          this.handlePagination();
        }
      );
    } else {
      this.setState({ hasNextSection: true });
    }
  };

  previousPagination = () => {
    const { navigationMenuSuccess, sectionId } = this.state;
    const sectionIndex = navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections.findIndex(
      (section) => section.id === sectionId
    );
    if (sectionIndex > 0) {
      const previousSectionId = navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections[sectionIndex - 1].id;
      this.setState(
        { sectionId: previousSectionId, hasNextSection: false, hasPreviousSection: false },
        () => {
          this.getSectionsUserDetails();
          this.handlePagination();
        }
      );
    } else {
      this.setState({ hasPreviousSection: true });
    }
  };

  handlePagination = () => {
    const { navigationMenuSuccess, sectionId } = this.state;
    const sectionIndex = navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections.findIndex(
      (section) => section.id === sectionId
    );
    if (sectionIndex === 0) {
      this.setState({
        hasPreviousSection: true,
        hasNextSection: false
      });
    } else if (sectionIndex === navigationMenuSuccess.data.user_survey_navigation_menu.user_survey_sections.length - 1) {
      this.setState({
        hasPreviousSection: false,
        hasNextSection: true
      });
    } else {
      this.setState({
        hasPreviousSection: false,
        hasNextSection: false
      });
      }
  };

  getDownloadWinZipFile = () => {
    const linkSharePath = window.location;
    let pathName = linkSharePath.pathname.split("/");
    let activeLinkId = pathName[pathName.length - 1];
    this.getWinZipApiCallId = this.guestDashboardApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.winZipFileEndPoint}${activeLinkId}`
    });
  };

  downloadZip = () => {
    this.setState({
      zipLoader: true
    }, () => {
      this.getDownloadWinZipFile();
    });
  };

  zipFile = () => {
    window.location.href = this.state.winZipLink;
  };

  getSavePdfDetails = () => {
    const linkPath = window.location;
    let savePathName = linkPath.pathname.split("/");
    let saveId = savePathName[savePathName.length - 1];
    this.getSaveAsPdfApiCallId = this.guestDashboardApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.saveAsPdfEndPoint}${saveId}`
    });
  };

  downloadPDF = async() => {
    const linkPath = window.location;
    let savePathName = linkPath.pathname.split("/");
    let pdfSurveyId = savePathName[savePathName.length - 1];
    let absolutePdfUrl = `${configJSON.savePdfUrl}${pdfSurveyId}`;
    let openPdfReportUrl = `${window.location.origin}/${absolutePdfUrl}`;
    window.open(openPdfReportUrl, "_blank");
  };

  fileErrorPopup = () => {
    this.setState({
      openPopup: !this.state.openPopup
    });
  };

  handleDownloadImage = (imageUrl: string, filename:string) => {
    fetch(imageUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };
  handleFormateDate = (date:string) =>{
    return moment(date).format('MM/DD/YYYY') 
   }
    // Customizable Area End
  
}